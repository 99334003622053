import React from 'react';
import { Container } from 'react-bootstrap';

const Loading = () => (
  <Container className="loading">
    <h1>Loading...</h1>
  </Container>
);

export default Loading;
