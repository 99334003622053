import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router';
import Prismic from '@prismicio/client';
import { RichText } from 'prismic-reactjs';
import { Helmet } from 'react-helmet';
import { PRISMIC_API, SITE_NAME } from '../Config/const';
import NotFound404 from '../Misc/NotFound404';
import Loading from '../Misc/Loading';

const Page = () => {
  const { uid } = useParams();
  const [entry, setEntry] = useState({});
  const client = Prismic.client(PRISMIC_API);
  useEffect(async () => {
    const doc = await client.getByUID('page', uid);
    setEntry(doc);
  }, [uid]);
  if (entry === undefined) return (<NotFound404 />);
  if (entry.data === undefined) return (<Loading />);
  console.log(entry);
  return (
    <div className="content">
      <Helmet>
        <title>{`${RichText.asText(entry.data.title)} - ${SITE_NAME}`}</title>
        <meta property="og:title" content={`${RichText.asText(entry.data.title)} - ${SITE_NAME}`} />
        {RichText.asText(entry.data.meta_description) !== '' ? (
          <meta name="description" content={RichText.asText(entry.data.meta_description)} />
        ) : null}
        {RichText.asText(entry.data.meta_description) !== '' ? (
          <meta property="og:description" content={RichText.asText(entry.data.meta_description)} />
        ) : null}
      </Helmet>

      <Container>
        <h1>{RichText.asText(entry.data.title)}</h1>
        <Row className="justify-content-md-center">
          <Col lg={8}>
            {RichText.render(entry.data.body)}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Page;
