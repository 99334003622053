/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router';

const Contact = () => {
  let subject = 'General Inquiry';
  const { type } = useParams();
  switch (type) {
    case 'interested':
      subject = 'Interested in Being a Counselor';
      break;
    default:
  }
  return (
    <div className="content">
      <Container>
        <h1>{`Contact Us - ${subject}`}</h1>
        <Row className="justify-content-md-center">
          <Col lg={6}>
            <form action="https://usebasin.com/f/c3edb6b8cf2f" method="POST">
              <label>Name</label>
              <input type="text" id="name" name="name" />
              <label>Your E-mail</label>
              <input type="text" id="email" name="email" />
              <label>Phone</label>
              <input type="text" id="phone" name="phone" />
              <label>Message</label>
              <textarea id="realmessage" name="realmessage" />
              <input type="hidden" id="message" name="message" />
              <input type="hidden" id="subject" name="subject" value={subject} />
              <input type="submit" value="Send" className="as-button" />
            </form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Contact;
