import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Prismic from '@prismicio/client';
import { RichText } from 'prismic-reactjs';
import { PRISMIC_API } from '../Config/const';
import Loading from '../Misc/Loading';

const Home = () => {
  const [entry, setEntry] = useState({});
  const client = Prismic.client(PRISMIC_API);
  useEffect(async () => {
    const doc = await client.getByUID('home', 'home');
    setEntry(doc);
  }, []);
  if (entry.data === undefined) return (<Loading />);
  // console.log(entry);

  return (
    <div>
      <section className="hero-div vertical-zero under-navbar">
        <Container>
          <Row className="align-items-stretch">
            <Col md={7} className="d-flex flex-column justify-content-end">
              <div className="copy">
                <h2 className="hero">{RichText.asText(entry.data.hero)}</h2>
                <h3 className="hero-tag">{entry.data.hero_tag}</h3>
                <Link to="/schedule" className="action">{entry.data.hero_action}</Link>
              </div>
              <div className="leaves">
                <img src="/img/leaves1.png" alt="leaves" />
              </div>
            </Col>
            <Col>
              <img src="/img/man.png" alt="happy guy" className="hero-man" />
            </Col>
          </Row>
        </Container>
      </section>
      <section className="content steps">
        <Container>
          <h2>{RichText.asText(entry.data.steps_title)}</h2>
          <Row className="justify-content-md-center">
            <Col lg={4}>
              <Link to="/counselors" className="action with-bullet">
                <strong>{entry.data.steps[0].action_no}</strong>
                {entry.data.steps[0].action}
              </Link>
              {RichText.render(entry.data.steps[0].action_description)}
            </Col>
            <Col lg={1}>&nbsp;</Col>
            <Col lg={4}>
              <Link to="/schedule" className="action with-bullet">
                <strong>{entry.data.steps[1].action_no}</strong>
                {entry.data.steps[1].action}
              </Link>
              {RichText.render(entry.data.steps[1].action_description)}
            </Col>
          </Row>
        </Container>
      </section>
      <section className="content services vertical-zero">
        <Container fluid>
          <Row>
            <Col lg={4} sm={6} className="graphics">&nbsp;</Col>
            <Col className="copy">
              <h2>{RichText.asText(entry.data.service_title)}</h2>
              <Row>
                <Col lg={6}>
                  <div className="icon">
                    <img src="/img/icon-person.png" alt="icon person" />
                  </div>
                  <h3>{RichText.asText(entry.data.services[0].service_title)}</h3>
                  <p>{entry.data.services[0].service_tag}</p>
                  {RichText.render(entry.data.services[0].service_description)}
                </Col>
                <Col>
                  <div className="icon">
                    <img src="/img/icon-person.png" alt="icon person" />
                    <img src="/img/icon-person.png" alt="icon person" />
                  </div>
                  <h3>{RichText.asText(entry.data.services[1].service_title)}</h3>
                  <p>{entry.data.services[1].service_tag}</p>
                  {RichText.render(entry.data.services[1].service_description)}
                </Col>
              </Row>
              <Row className="justify-content-md-center">
                <Col lg={6}>
                  <div className="note">
                    {RichText.render(entry.data.service_note)}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="content story">
        <Container>
          <img src="/img/leaves2.png" alt="leaves" className="leaves" />
          <h2>{entry.data.story_title}</h2>
          <Row className="justify-content-md-center">
            <Col lg={6} md={8}>
              {RichText.render(entry.data.story)}
            </Col>
          </Row>
        </Container>
      </section>
      <section className="content quote vertical-large">
        <Container>
          <h2>
            <em>“</em>
            {RichText.asText(entry.data.quote)}
            <em>”</em>
          </h2>
          <p><i>{`- ${entry.data.quote_by}`}</i></p>
        </Container>
      </section>
      <section className="content contact">
        <Container>
          <h2>Get in Touch</h2>
          <Link to="/contact" className="action">Contact Us</Link>
        </Container>
      </section>
      <section className="content crisis">
        <Container>
          <Link to="/page/crisis-resource">Crisis Resources</Link>
        </Container>
      </section>
    </div>
  );
};

export default Home;
