import React from 'react';
import { Container } from 'react-bootstrap';

const NotFound404 = () => (
  <Container className="not-found-404">
    <h1>Not Found</h1>
  </Container>
);

export default NotFound404;
