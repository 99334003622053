import React, { useState } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

const GlobalNav = () => {
  const [expanded, setExpanded] = useState(false);
  const toggle = () => {
    setExpanded(!expanded);
  };
  const forceClose = () => {
    setExpanded(false);
  };
  return (
    <Navbar
      bg="light"
      expand="lg"
      expanded={expanded}
      onToggle={toggle}
      onSelect={forceClose}
    >
      <LinkContainer to="/">
        <Navbar.Brand onClick={forceClose}>
          <img src="/img/logo.png" alt="H2H" />
        </Navbar.Brand>
      </LinkContainer>
      <Navbar.Toggle aria-controls="basic-navbar-nav"/>
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          <LinkContainer to="/schedule">
            <Nav.Link>Schedule Apointment</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/counselors">
            <Nav.Link>Our Counselors</Nav.Link>
          </LinkContainer>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default GlobalNav;
