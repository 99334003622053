import React, { useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare, faTimes } from '@fortawesome/free-solid-svg-icons';

const CounselorModal = () => {
  const [open, setOpen] = useState(false);
  const toggleOpen = () => {
    setOpen(!open);
  };
  return (
    <div className="counselor-div">
      <button type="button" className="as-text" onClick={() => toggleOpen()}>
        <img src="/img/alicia-photo.png" alt="Alicia Cherian" />
        <div className="name">
          <FontAwesomeIcon icon={faPlusSquare} />
          Alicia Cherian
        </div>
        <div className="services">
          Individual, Dating, Pre-Parital, Marital
        </div>
      </button>
      <Modal show={open} size="lg" onHide={toggleOpen}>
        <Modal.Body className="counselor-modal">
          <div className="close-div">
            <button type="button" className="as-text" onClick={toggleOpen}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
          <Row>
            <Col sm="4">
              <img src="/img/alicia-photo.png" alt="Alicia Cherian" />
            </Col>
            <Col>
              <div className="name">Alicia Cherian</div>
              <div className="license">Licensed Marriage and Family Therapist (MA - LMFT)</div>
              <div className="attribute">
                <div>
                  <b>Sepcialty:</b>
                  {' '}
                  Individual, Pre-Marital/Dating, Marital
                </div>
                <div>
                  <b>Region:</b>
                  {' '}
                  New York
                </div>
              </div>
              <p>
                As a Licensed Mental Health Professional, I warmly lean in to
                my calling to SEE people where they are for their joy, or pain,
                and give a safe space to be KNOWN. I find it an honor to walk
                alongside you in your life changes and help you navigate through
                the cloudy water life can inevitably be like in various seasons.
              </p>
              <p>
                I can help you create boundaries, identify patterns from past
                and present and help make changes you want for a balanced life.
                I have expertise in providing christian counseling if that is
                your preference.
              </p>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CounselorModal;
