import React, { useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router';
import { Helmet } from 'react-helmet';
import GlobalNav from './Misc/GlobalNav';
import NotFound404 from './Misc/NotFound404';

import Counselors from './Pages/Counselors';
import Home from './Pages/Home';
import Schedule from './Pages/Schedule';
import { SITE_DESCRIPTION, SITE_NAME, SITE_IMAGE } from './Config/const';
import Contact from './Pages/Contact';
import Page from './Pages/Page';

const App = () => {
  const { pathname } = useLocation();
  const wapperClass = pathname.split('/').join(' ').trim();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div className={wapperClass !== '' ? wapperClass : 'home'}>
      <Helmet>
        <title>{SITE_NAME}</title>
        <meta property="og:title" content={SITE_NAME} />
        <meta name="description" content={SITE_DESCRIPTION} />
        <meta property="og:description" content={SITE_DESCRIPTION} />
        <meta property="og:type" content="article" />
        <meta property="og:image" content={SITE_IMAGE} />
      </Helmet>
      <GlobalNav />
      <Switch>
        <Route path="/page/:uid" exact component={Page} />
        <Route path="/counselors" exact component={Counselors} />
        <Route path="/contact" exact component={Contact} />
        <Route path="/contact/:type" component={Contact} />
        <Route path="/schedule" exact component={Schedule} />
        <Route path="/" exact component={Home} />
        <Route component={NotFound404} />
      </Switch>
    </div>
  );
};

export default App;
