import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import CounselorModal from './CounselorModal';

const Counselors = () => (
  <div className="content">
    <section className="headshots bg-wall under-navbar">
      <Container>
        <h1>Counselors</h1>
        <Row className="justify-content-md-center">
          <Col lg={3} md={4} xs={6}>
            <CounselorModal />
          </Col>
        </Row>
        <div className="more-soon">
          <div className="as-button outline">More Counselors Coming Soon</div>
        </div>
        <div className="leaves">
          <img src="/img/leaves3.png" alt="leaves" />
        </div>
      </Container>
    </section>
    <section className="join-team">
      <Container>
        <h2>Interested In Joining the Team?</h2>
        <p>Licensed counselors interested i joining the team, please contact us.</p>
        <Link to="/contact/interested" className="as-button">I&rsquo;m Interested</Link>
      </Container>
    </section>
  </div>
);

export default Counselors;
