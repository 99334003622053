import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

const Schedule = () => (
  <div className="content">
    <Container>
      <h1>Schedule an Appointment</h1>
      <Row className="justify-content-md-center">
        <Col lg={8}>
          <iframe
            src="https://app.squarespacescheduling.com/schedule.php?owner=22276912"
            title="Schedule Appointment"
            width="100%"
            height="800"
            frameBorder="0"
          />
        </Col>
      </Row>
    </Container>
  </div>
);

export default Schedule;
